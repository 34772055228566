@font-face {
  font-family: Gill Sans;
  src: url("https://meowapp.b-cdn.net/static/fonts/GillSans-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: Gill Sans;
  src: url("https://meowapp.b-cdn.net/static/fonts/GillSans-Light.ttf") format("truetype");
  font-weight: 100;
}

@font-face {
  font-family: Gill Sans;
  src: url("https://meowapp.b-cdn.net/static/fonts/GillSans-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

:root {
  --bckgdColor: #181816;
  --offBckgdColor: #111;
  --overlayColor: #1e1e1eb3;
  --secondaryOverlayColor: #47474180;
  --sideBarColor: #474741;
  --buttonColor: #57ded8;
  --buttonTextColor: #000;
  --textColor: #000;
  --textLightColor: #faebd7;
  --inputColor: #474741;
  --inputTextColor: #faebd7;
  --placeHolderColor: #9f9283;
  --successColor: #7bff84;
  --blue: #57ded8;
  --red: #f97979;
  --orange: #deaf57;
  --errColor: #f97979;
  --chatMessageColor: #152a29;
  --chatMessageRemoteColor: #563030;
  --headerHeight: 50px;
  --mobilePadding: 12px;
  --sideBarWidth: 0px;
  --chatViewWidth: 0px;
  --speakerViewWidth: 0px;
  --speakerViewHeight: 0px;
  --vh: 1vh;
}

*, :after, :before {
  box-sizing: border-box;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

::-webkit-scrollbar {
  display: none;
}

.flex-container {
  display: flex;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.flex-col {
  flex-direction: column;
}

.no-display {
  display: none !important;
}

.hidden {
  visibility: hidden !important;
}

.no-scroll {
  overflow: hidden;
}

.no-pointers {
  pointer-events: none !important;
}

.ease-out {
  -o-transition: .3s ease-out;
  transition: all .3s ease-out;
}

.cubic-bezier {
  -o-transition: .3s cubic-bezier(.57, -.21, 0, .74);
  transition: all .3s cubic-bezier(.57, -.21, 0, .74);
}

.transform-y100 {
  transform: translateY(100%);
}

.transform-y-100 {
  transform: translateY(-100%);
}

.transform-x-100 {
  transform: translateX(-100%);
}

.transform-x100 {
  transform: translateX(100%);
}

.video-transform {
  -o-transform: translate(var(--offsetX), var(--offsetY));
  -moz-transform: translate(var(--offsetX), var(--offsetY));
  -webkit-transform: translate(var(--offsetX), var(--offsetY));
  -ms-transform: translate(var(--offsetX), var(--offsetY));
  transform: translate(var(--offsetX), var(--offsetY));
}

.no-opacity {
  opacity: 0 !important;
}

.text-center {
  text-align: center;
}

.read-text {
  font-size: 1em;
  line-height: 1.5em;
}

.no-margin {
  margin: 0 !important;
}

.margin-1 {
  margin: 1em !important;
}

.error {
  color: var(--errColor) !important;
}

.bckg {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.btns {
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  flex-shrink: 0;
  padding: 0;
  font-family: Gill Sans, Gill Sans MT, Myriad Pro, DejaVu Sans Condensed, Helvetica, Arial, "sans-serif";
}

button:disabled {
  opacity: .8;
  background-color: var(--buttonColor) !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  color: var(--buttonTextColor) !important;
  cursor: default !important;
}

.spinner, .spinner:before {
  border-radius: 50%;
}

.spinner {
  color: var(--buttonTextColor);
  text-indent: -99999px;
  width: 20px;
  height: 20px;
  margin: 0 auto;
  font-size: 22px;
  position: relative;
  overflow: hidden;
  transform: translateZ(0);
  box-shadow: inset 0 0 0 2px;
}

.spinner:before {
  content: "";
  background: var(--buttonColor);
  transform-origin: 10.4px 10.2px;
  -webkit-animation: 2s 1.5s infinite loading;
  border-radius: 20.4px 0 0 20.4px;
  width: 10.4px;
  height: 20.4px;
  animation: 1s linear infinite loading;
  position: absolute;
  top: -.2px;
  left: -.2px;
}

@keyframes loading {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.lds-ellipsis {
  width: 80px;
  height: 80px;
  display: inline-block;
  position: relative;
}

.lds-ellipsis div {
  background: var(--blue);
  border-radius: 50%;
  width: 13px;
  height: 13px;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
  position: absolute;
  top: 33px;
}

.lds-ellipsis div:first-child {
  animation: .6s infinite lds-ellipsis1;
  left: 8px;
}

.lds-ellipsis div:nth-child(2) {
  animation: .6s infinite lds-ellipsis2;
  left: 8px;
}

.lds-ellipsis div:nth-child(3) {
  animation: .6s infinite lds-ellipsis2;
  left: 32px;
}

.lds-ellipsis div:nth-child(4) {
  animation: .6s infinite lds-ellipsis3;
  left: 56px;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0);
  }

  100% {
    transform: translate(24px);
  }
}

.please-wait {
  width: 100%;
}

.please-wait .read-text {
  text-align: center;
  color: var(--blue);
  margin: 0;
}

html, body {
  -webkt-scroll-behavior: smooth;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  margin: 0;
  padding: 0;
}

html {
  background-color: var(--bckgdColor);
  text-size-adjust: 100%;
  font-family: Gill Sans, Gill Sans MT, Myriad Pro, DejaVu Sans Condensed, Helvetica, Arial, "sans-serif";
}

#infoWrapper {
  width: 100vw;
  height: 100vh;
}

#infoWrapper img {
  width: 80%;
  max-width: 200px;
}

#boxWrapper {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
}

#box {
  background-color: var(--blue);
  text-align: center;
  opacity: 0;
  border-radius: 6px;
  width: calc(100% - 40px);
  max-width: 420px;
  min-height: 1px;
  padding: 20px;
  display: none;
}

#box a:link, #box a:visited {
  color: var(--offBckgdColor);
  font-weight: 700;
  text-decoration: underline;
}

#box h1, #box h2, #box p {
  color: var(--textColor);
}

#resultOverlay {
  z-index: 999;
}

#fileDropOverlay {
  border: 2px solid var(--blue);
}

#fileDropOverlay p {
  color: var(--blue);
  font-size: 2em;
}

.overlay {
  width: 100%;
  height: calc(100 * var(--vh));
  cursor: pointer;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background-color: #1e1e1ee6;
  position: fixed;
  top: 0;
  left: 0;
}

.overlay-change {
  visibility: visible !important;
  opacity: 1 !important;
}

.overlay-box {
  background-color: var(--offBckgdColor);
  width: 95%;
  max-width: 400px;
  height: auto;
  max-height: 500px;
  color: var(--textLightColor);
  cursor: default;
  border-radius: 6px;
  padding-bottom: 20px;
  overflow-y: scroll;
  transform: scale(0);
}

.overlay-box-change {
  transform: scale(1);
}

.overlay-box h1 {
  text-align: center;
}

.overlay-box p {
  text-align: center;
  margin: 2em 0;
}

.overlay-content {
  padding: 0 20px;
}

.overlay-content-sticky-header {
  background-color: var(--offBckgdColor);
  z-index: 1;
  width: 100%;
  padding-bottom: 20px;
  position: sticky;
  top: 0;
}

.input-fields {
  width: 100%;
  max-width: 320px;
  height: 40px;
  color: var(--inputTextColor);
  background-color: var(--inputColor);
  border: none;
  border-radius: 6px;
  outline: none;
  margin: 10px 0 0;
  padding: 10px;
  font-family: Gill Sans, Gill Sans MT, Myriad Pro, DejaVu Sans Condensed, Helvetica, Arial, "sans-serif";
}

.input-readonly {
  color: var(--placeHolderColor);
  cursor: default;
  background-color: var(--offBckgdColor) !important;
}

input:-webkit-autofill {
  -webkit-text-fill-color: var(--inputTextColor);
  -webkit-box-shadow: 0 0 0px 1000px var(--inputColor) inset;
  box-shadow: 0 0 0px 1000px var(--inputColor) inset;
  -o-transition: background-color 5000s ease-in-out 0s;
  border: none;
  transition: background-color 5000s ease-in-out;
}

input:-webkit-autofill:hover {
  -webkit-text-fill-color: var(--inputTextColor);
  -webkit-box-shadow: 0 0 0px 1000px var(--inputColor) inset;
  box-shadow: 0 0 0px 1000px var(--inputColor) inset;
  -o-transition: background-color 5000s ease-in-out 0s;
  border: none;
  transition: background-color 5000s ease-in-out;
}

input:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--inputTextColor);
  -webkit-box-shadow: 0 0 0px 1000px var(--inputColor) inset;
  box-shadow: 0 0 0px 1000px var(--inputColor) inset;
  -o-transition: background-color 5000s ease-in-out 0s;
  border: none;
  transition: background-color 5000s ease-in-out;
}

textarea:-webkit-autofill {
  -webkit-text-fill-color: var(--inputTextColor);
  -webkit-box-shadow: 0 0 0px 1000px var(--inputColor) inset;
  box-shadow: 0 0 0px 1000px var(--inputColor) inset;
  -o-transition: background-color 5000s ease-in-out 0s;
  border: none;
  transition: background-color 5000s ease-in-out;
}

textarea:-webkit-autofill:hover {
  -webkit-text-fill-color: var(--inputTextColor);
  -webkit-box-shadow: 0 0 0px 1000px var(--inputColor) inset;
  box-shadow: 0 0 0px 1000px var(--inputColor) inset;
  -o-transition: background-color 5000s ease-in-out 0s;
  border: none;
  transition: background-color 5000s ease-in-out;
}

textarea:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--inputTextColor);
  -webkit-box-shadow: 0 0 0px 1000px var(--inputColor) inset;
  box-shadow: 0 0 0px 1000px var(--inputColor) inset;
  -o-transition: background-color 5000s ease-in-out 0s;
  border: none;
  transition: background-color 5000s ease-in-out;
}

select:-webkit-autofill {
  -webkit-text-fill-color: var(--inputTextColor);
  -webkit-box-shadow: 0 0 0px 1000px var(--inputColor) inset;
  box-shadow: 0 0 0px 1000px var(--inputColor) inset;
  -o-transition: background-color 5000s ease-in-out 0s;
  border: none;
  transition: background-color 5000s ease-in-out;
}

select:-webkit-autofill:hover {
  -webkit-text-fill-color: var(--inputTextColor);
  -webkit-box-shadow: 0 0 0px 1000px var(--inputColor) inset;
  box-shadow: 0 0 0px 1000px var(--inputColor) inset;
  -o-transition: background-color 5000s ease-in-out 0s;
  border: none;
  transition: background-color 5000s ease-in-out;
}

select:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--inputTextColor);
  -webkit-box-shadow: 0 0 0px 1000px var(--inputColor) inset;
  box-shadow: 0 0 0px 1000px var(--inputColor) inset;
  -o-transition: background-color 5000s ease-in-out 0s;
  border: none;
  transition: background-color 5000s ease-in-out;
}

select {
  appearance: none;
  background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMC4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCA0LjkgMTAiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDQuOSAxMDsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4NCgkuc3Qwe2ZpbGw6I2ZmZmZmZjt9DQo8L3N0eWxlPg0KPHRpdGxlPmFycm93czwvdGl0bGU+DQo8cG9seWdvbiBjbGFzcz0ic3QwIiBwb2ludHM9IjEuNCw0LjcgMi41LDMuMiAzLjUsNC43ICIvPg0KPHBvbHlnb24gY2xhc3M9InN0MCIgcG9pbnRzPSIzLjUsNS4zIDIuNSw2LjggMS40LDUuMyAiLz4NCjwvc3ZnPg==") 95% no-repeat;
}

select:required:invalid {
  color: gray;
}

option {
  color: #fff;
}

textarea {
  resize: none;
  min-height: 35px !important;
}

::placeholder {
  color: var(--placeHolderColor);
  opacity: 1;
}

:-ms-input-placeholder {
  color: var(--placeHolderColor);
}

::-moz-placeholder {
  color: var(--placeHolderColor);
}

.submit-btn {
  background-color: var(--buttonColor);
  min-width: 110px;
  height: 34px;
  color: var(--buttonTextColor);
  border: none;
  border-radius: 6px;
  outline: none;
  margin: 10px auto;
  padding: 5px 20px;
  font-size: 1em;
  font-weight: 600;
  display: inline-block;
}

.error-btn {
  background-color: var(--errColor) !important;
}

.login-container {
  width: 100%;
  min-height: calc(100 * var(--vh));
  justify-content: center;
}

.logo {
  background-image: url("https://meowapp.b-cdn.net/static/images/logo.png");
  flex-shrink: 0;
  width: 100px;
  height: 100px;
  margin: 0 auto;
}

.login-overlay {
  width: calc(100% - var(--mobilePadding));
  max-height: 700px;
  padding: 20px;
}

.app-title {
  color: var(--textLightColor);
  margin-bottom: 0;
}

.app-subtitle {
  color: var(--placeHolderColor);
}

#loginForm {
  width: 100%;
  max-width: 200px;
}

#signupForm {
  align-items: center;
  width: 100%;
  max-width: 260px;
}

#signupBtn {
  width: 100%;
  margin: 0 auto;
}

.pass-wrapper {
  align-items: center;
  width: 100%;
}

#loginBtn, #forgotBtn {
  width: 100%;
  margin: 10px 0;
}

.show-btn {
  background-color: var(--inputColor);
  color: var(--inputTextColor);
  border: none;
  border-radius: 0 6px 6px 0;
  outline: none;
  height: 40px;
  margin-top: 10px;
  margin-left: -6px;
  padding: 0 10px;
  font-size: .8em;
  font-weight: 100;
}

#forgotPass {
  text-align: center;
  color: var(--textLightColor);
  cursor: pointer;
  margin: 0;
}

.success-logout {
  text-align: center;
  height: 40px;
  color: var(--successColor);
  background-color: var(--chatMessageColor);
  border-radius: 5px;
  margin: 20px 0;
  padding: 5px 10px;
}

#settingsOverlay .overlay-box {
  width: calc(100% - var(--mobilePadding));
}

.settings-item {
  background-color: var(--bckgdColor);
  text-align: center;
  width: 100%;
  height: 50px;
  color: var(--textLightColor);
  cursor: pointer;
  border-radius: 5px;
  margin: 5px 0;
  padding: 5px 15px;
  text-decoration: none;
}

#settingsView {
  -webkit-box-align: unset;
  -ms-flex-align: unset;
  align-items: unset;
  margin: 0 auto;
  padding-top: 50px;
}

#exitSettingsBtn {
  margin: 20px auto;
  position: absolute;
  top: 0;
}

#settingsView h1, #settingsView h2 {
  color: var(--textLightColor);
}

#settingsView form {
  width: 100%;
  max-width: 290px;
  margin-top: 20px;
  display: block;
}

#settingsWrapper {
  padding-bottom: 100px;
}

#settingsWrapper p {
  color: var(--textLightColor);
  max-width: 320px;
}

.setting-page {
  width: calc(100% - var(--mobilePadding));
}

#changePassForm, #newPassCriteria {
  width: 100%;
  max-width: 290px;
}

#newPassCriteria {
  margin: 10px 0;
}

#newPassCriteria p {
  color: var(--textLightColor);
}

#changePassForm label {
  font-size: 1em;
}

#resetPassBtn, #inviteBtn {
  width: 100%;
  margin: 10px 0;
}

#resetPassBtn {
  margin-top: 0;
}

.pass-criteria-wrapper {
  background-color: var(--chatMessageColor);
  border-radius: 6px;
  padding: 5px 10px;
  font-size: .8em;
}

.pass-criteria {
  margin: 10px 0;
  font-weight: 100;
}

.pass-criteria span {
  color: var(--placeHolderColor);
}

.pass-criteria-success {
  color: var(--blue) !important;
  font-weight: 400 !important;
}

.pass-criteria-text {
  margin-left: 10px;
}

.reset-pass:disabled {
  background-color: var(--sideBarColor) !important;
}

#fixedContainer {
  z-index: 3;
  width: 100%;
  position: fixed;
  top: 0;
}

#sideBar {
  background-color: var(--sideBarColor);
  width: 280px;
  padding: 50px 20px 0 50px;
  left: -30px;
  box-shadow: 1px 1px 5px #000000a1;
}

#sideBar, #chatView {
  height: calc(100 * var(--vh));
  z-index: 1;
  position: absolute;
  top: 0;
  overflow: hidden scroll;
}

#header {
  background-color: var(--bckgdColor);
  z-index: 3;
  width: 100%;
  height: 50px;
  color: var(--blue);
  justify-content: space-between;
  align-items: center;
  padding: 0 10px 0 40px;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 1px 12px #000;
}

#userName {
  text-transform: uppercase;
  text-align: right;
  font-weight: 600;
}

#closeDrawerBtn {
  margin-left: 10px;
}

#settingsBtn {
  cursor: pointer;
  background-image: url("https://meowapp.b-cdn.net/static/images/logo.png");
  border-radius: 50%;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  margin-right: 20px;
}

#openDrawerBtn {
  background-color: var(--bckgdColor);
  border: 1px solid var(--blue);
  z-index: 1;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 15px;
  left: 20px;
}

.sidebar-title-wrapper {
  z-index: 1;
  position: sticky;
  top: 0;
}

#sideBar .sidebar-title-wrapper {
  background-color: var(--sideBarColor);
}

#sideBar h2 {
  color: var(--textLightColor);
}

.sidebar-section, .sidebar-title-wrapper {
  border-bottom: 1px solid var(--bckgdColor);
}

.sidebar-section-users {
  padding-bottom: 20px;
}

.sidebar-section-flex {
  justify-content: space-between;
  align-items: center;
}

.sidebar-title {
  color: var(--textLightColor);
}

.sidebar-btns {
  background-color: #18181680;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

#addRoomBtn svg {
  position: relative;
  top: 1px;
}

#roomForm {
  margin-top: 20px;
}

#roomSubmitBtn {
  width: 100%;
}

.room-item {
  text-align: center;
  color: var(--blue);
  background-color: var(--bckgdColor);
  cursor: pointer;
  border-radius: 6px;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  margin: 10px 0;
  padding: 0 10px;
}

.room-item-name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.room-item-active {
  color: var(--textColor);
  -webkit-box-shadow: 0px 1px 10px var(--blue);
  box-shadow: 0px 1px 10px var(--blue);
  background-color: var(--blue) !important;
  cursor: default !important;
}

.notif {
  background-color: var(--blue);
  border-radius: 20px;
  min-width: 25px;
  height: 25px;
  padding: 0 5px;
}

#addUsersRoomName {
  font-weight: 700;
  text-decoration: underline;
}

#addUsersForm {
  width: 100%;
}

.select-users-wrapper {
  margin: 10px 0;
}

.custom-input-wrapper {
  cursor: pointer;
  user-select: none;
  border-bottom: 1px solid var(--inputColor);
  align-items: center;
  margin: 10px 0;
  padding-bottom: 10px;
  position: relative;
}

.custom-input-wrapper input {
  opacity: 0;
  cursor: pointer;
  width: 1px;
  height: 1px;
  position: absolute;
  top: 0;
  left: 0;
}

.checkmark {
  background-color: var(--inputColor);
  border-radius: 50%;
  width: 25px;
  height: 25px;
}

.checkmark-value {
  color: var(--inputColor);
  margin-left: 10px;
  font-size: 1em;
  font-weight: 700;
}

.custom-input-wrapper input:checked ~ .checkmark {
  background-color: var(--blue) !important;
}

.custom-input-wrapper input:checked ~ .checkmark-value {
  color: var(--blue) !important;
}

#addUsersBtn {
  width: 100%;
}

#addUserBtn svg {
  position: relative;
  left: 1px;
}

.user-wrapper {
  justify-content: space-between;
  align-items: center;
  height: 30px;
}

.pulse {
  background-color: var(--blue);
  border-radius: 50%;
  flex-shrink: 0;
  width: 8px;
  height: 8px;
  animation: .8s ease-in-out infinite alternate pulse;
}

@keyframes pulse {
  from {
    opacity: 1;
  }

  to {
    opacity: .3;
  }
}

.user-item {
  text-transform: capitalize;
  color: var(--placeHolderColor);
}

.user-item-active {
  color: var(--blue) !important;
}

#chatView {
  background-color: var(--offBckgdColor);
  width: 350px;
  right: -30px;
  box-shadow: -1px 1px 5px #000000a1;
}

#chatView .sidebar-title-wrapper {
  background-color: var(--offBckgdColor);
  padding: 0 50px 0 20px;
}

#closeChatBtn svg {
  transform: rotate(180deg);
}

#chatForm {
  width: calc(100% - 30px);
  position: sticky;
  bottom: 0;
  left: 0;
  overflow: hidden;
}

.chat-form-wrapper {
  background-color: var(--sideBarColor);
  z-index: 1;
  padding: 5px;
  position: relative;
}

#chatInput {
  background-color: var(--bckgdColor);
  height: 35px;
  max-height: calc(75 * var(--vh));
  border-radius: 10px;
}

#chatForm textarea {
  margin: 0;
}

#uploadBtn, #submitBtn {
  align-self: flex-end;
  width: 30px;
  height: 32px;
}

#submitBtn {
  margin-right: 5px;
}

#submitBtn:disabled {
  background-color: #0000 !important;
}

#submitBtn .spinner {
  color: var(--blue);
}

#submitBtn .spinner:before {
  background-color: var(--sideBarColor);
}

.submit-btn-active span svg path {
  fill: var(--blue);
}

#fileErrorWrapper {
  background-color: var(--bckgdColor);
  padding: 10px 20px;
  position: relative;
  transform: translateY(100%);
}

.file-error-change {
  transform: none !important;
}

#fileReveal {
  background-color: var(--bckgdColor);
  z-index: 1;
  padding: 20px;
  position: relative;
  transform: translateY(100%);
}

.file-reveal-change {
  transform: none !important;
}

.file-reveal-box {
  border: 1px solid var(--sideBarColor);
  border-radius: 10px;
  align-items: center;
  padding: 20px;
  position: relative;
}

.file-info {
  overflow: hidden;
}

#fileName {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: var(--textLightColor) !important;
}

#fileSize {
  color: var(--placeHolderColor);
  font-size: .8em;
}

#removeFileBtn {
  position: absolute;
  top: -8px;
  right: -8px;
}

#sendFileBtn {
  width: 25px;
  position: absolute;
  bottom: 5px;
  right: 5px;
}

#sendFileBtn .spinner {
  color: var(--successColor);
}

#sendFileBtn .spinner:before {
  background-color: var(--bckgdColor);
}

#sendFileBtn:disabled {
  background-color: #0000 !important;
}

#progressWrapper {
  padding-top: 10px;
}

.progress-user-wrapper {
  justify-content: left;
  gap: 10px;
  width: 100%;
}

.progress-bar {
  background-color: var(--secondaryOverlayColor);
  border-radius: 100vmax;
  width: 100%;
  height: 5px;
  overflow: hidden;
}

.progress {
  background-color: var(--successColor);
  width: 0%;
  height: 5px;
}

.progress-perc {
  color: var(--blue) !important;
}

.progress-user-wrapper p {
  -ms-grid-row-align: center;
  color: var(--successColor);
  align-self: center;
}

.progress-user {
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
  width: 50%;
  margin: 5px 0;
  overflow: hidden;
  color: var(--placeHolderColor) !important;
}

.progress-user-wrapper .error {
  font-size: .8em;
}

.file-received:link, .file-received:visited {
  color: var(--textLightColor);
}

.file-received {
  background-color: var(--offBckgdColor);
  width: 100%;
  -webkit-box-shadow: 0px 1px 5px var(--offBckgdColor);
  box-shadow: 0px 1px 5px var(--offBckgdColor);
  border-radius: 10px;
  align-items: center;
  margin: 10px 0;
  padding: 20px;
  text-decoration: none;
  position: relative;
}

.file-icon {
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.file-name {
  color: var(--orange);
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 400;
  overflow: hidden;
}

.file-size {
  color: var(--placeHolderColor);
  font-size: .8em;
}

#chatHist {
  width: calc(100% - 30px);
  min-height: calc(calc(100 * var(--vh))  - 96px);
  padding: 5px 25px;
}

.chat-message {
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 10px 10px 20px;
  position: relative;
}

.chat-message-remote:before, .chat-message-current:before {
  content: "";
  width: 30px;
  height: 30px;
  margin-left: 100px;
  display: block;
  position: absolute;
  top: calc(100% - 35px);
  right: calc(100% - 10px);
  transform: rotate(-90deg);
}

.chat-message-current:before {
  background: radial-gradient(circle at 100% 0, #0000 30px, #152a29 0);
}

.chat-message-remote:before {
  background: radial-gradient(circle at 100% 0, #0000 30px, #563030 0);
}

.chat-message-remote {
  background-color: var(--chatMessageRemoteColor);
}

.chat-message-current {
  background-color: var(--chatMessageColor);
}

.chat-user {
  text-transform: capitalize;
  word-break: break-word;
  flex-shrink: 0;
  margin: 0;
  font-weight: 100;
}

.chat-text {
  word-break: break-word;
  margin: 0 0 10px;
  color: var(--textLightColor) !important;
}

.chat-message-current .chat-user {
  color: var(--blue) !important;
}

.chat-message-remote .chat-user {
  color: var(--red) !important;
}

.msg-time {
  color: var(--placeHolderColor);
  float: right;
  flex-shrink: 0;
  align-self: flex-end;
  font-size: 10px;
}

.user-typing {
  width: fit-content;
  min-width: 65px;
}

.user-typing .lds-ellipsis {
  width: 0;
  height: 20px;
  transform: scale(.5);
}

.user-typing .lds-ellipsis div {
  top: 19px;
  background-color: var(--textLightColor) !important;
}

.new-day {
  color: var(--placeHolderColor);
  font-size: .8em;
}

.hyperlinks:link, .hyperlinks:visited {
  color: var(--orange);
  word-break: break-word;
  cursor: pointer;
  font-weight: 400;
}

.page {
  min-height: calc(100 * var(--vh));
  padding: 10px;
  position: relative;
}

.main-page {
  width: 100%;
  position: fixed;
}

.main-view-change {
  width: calc(100% - 250px);
  --sideBarWidth: 250px !important;
  width: calc(100% - calc(var(--sideBarWidth)  + var(--chatViewWidth))) !important;
  margin-left: 250px !important;
}

.main-view-chat-change {
  --chatViewWidth: 320px !important;
  width: calc(100% - calc(var(--sideBarWidth)  + var(--chatViewWidth))) !important;
  margin-right: 320px !important;
}

#welcomeView {
  width: 100%;
  color: var(--textLightColor);
  max-height: 700px;
}

#welcomeView h1 {
  margin-bottom: 0;
}

#getStartedText {
  color: var(--blue);
}

#roomView {
  width: 100%;
  height: calc(calc(100 * var(--vh)  - 20px));
  -o-transition: width .3s cubic-bezier(.57, -.21, 0, .74), margin .3s cubic-bezier(.57, -.21, 0, .74);
  transition: width .3s cubic-bezier(.57, -.21, 0, .74), margin .3s cubic-bezier(.57, -.21, 0, .74);
  position: relative;
}

.view-btn {
  opacity: 1;
  z-index: 2;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 10px;
  right: 10px;
}

.view-btn[data-view="mosaic"] {
  background-color: var(--bckgdColor);
}

.view-btn[data-view="speaker"] {
  background-color: var(--bckgdColor);
  padding: 2px;
}

#videoGrid {
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin: auto;
  position: relative;
  top: 50%;
}

.speaker-grid-view {
  height: 100px !important;
  top: unset !important;
  flex-wrap: nowrap !important;
}

.video-grid-overflow {
  justify-content: flex-start !important;
  overflow: scroll hidden !important;
}

#myVideo {
  border: 2px solid var(--bckgdColor);
  cursor: move;
  z-index: 1;
  -o-object-fit: cover;
  object-fit: cover;
  width: 200px;
  height: 112.5px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.my-video-change {
  top: unset !important;
  right: unset !important;
  cursor: pointer !important;
  border: none !important;
  position: relative !important;
}

.video-wrapper {
  position: relative;
}

.video {
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 6px;
  width: 100%;
  height: 100%;
}

.video-info {
  background-color: #47474191;
  border-radius: 0 5px 0 0;
  align-items: center;
  gap: 5px;
  padding: 5px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.name-tag {
  color: #fff;
  text-transform: capitalize;
  pointer-events: none;
  font-size: .8em;
}

.mute-icon {
  pointer-events: none;
}

.name-tag-center {
  width: 100%;
  color: var(--sideBarColor);
  text-transform: capitalize;
  text-align: center;
  pointer-events: none;
  font-size: 2em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.lds-spinner {
  width: 80px;
  height: 80px;
  display: inline-block;
  position: relative;
}

.lds-spinner div {
  transform-origin: 40px 40px;
  animation: 1.2s linear infinite lds-spinner;
}

.lds-spinner div:after {
  content: " ";
  background: var(--blue);
  border-radius: 20%;
  width: 6px;
  height: 18px;
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
}

.lds-spinner div:first-child {
  animation-delay: -1.1s;
  transform: rotate(0);
}

.lds-spinner div:nth-child(2) {
  animation-delay: -1s;
  transform: rotate(30deg);
}

.lds-spinner div:nth-child(3) {
  animation-delay: -.9s;
  transform: rotate(60deg);
}

.lds-spinner div:nth-child(4) {
  animation-delay: -.8s;
  transform: rotate(90deg);
}

.lds-spinner div:nth-child(5) {
  animation-delay: -.7s;
  transform: rotate(120deg);
}

.lds-spinner div:nth-child(6) {
  animation-delay: -.6s;
  transform: rotate(150deg);
}

.lds-spinner div:nth-child(7) {
  animation-delay: -.5s;
  transform: rotate(180deg);
}

.lds-spinner div:nth-child(8) {
  animation-delay: -.4s;
  transform: rotate(210deg);
}

.lds-spinner div:nth-child(9) {
  animation-delay: -.3s;
  transform: rotate(240deg);
}

.lds-spinner div:nth-child(10) {
  animation-delay: -.2s;
  transform: rotate(270deg);
}

.lds-spinner div:nth-child(11) {
  animation-delay: -.1s;
  transform: rotate(300deg);
}

.lds-spinner div:nth-child(12) {
  animation-delay: 0s;
  transform: rotate(330deg);
}

@keyframes lds-spinner {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.reconnecting-loader {
  width: 80px;
  height: 80px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.speaker-grid-view .reconnecting-loader {
  transform: translate(-50%, -50%)scale(.5);
}

.speaker-grid-view .name-tag-center {
  font-size: 1em;
}

.speaker-grid-view .video {
  cursor: pointer !important;
  width: 178px !important;
  height: 100% !important;
}

.active-speaker-view .video {
  border: 1px solid var(--blue);
}

#speakerView {
  width: var(--speakerViewWidth);
  flex-wrap: wrap;
  justify-content: center;
  margin: auto;
  padding-top: 10px;
  position: relative;
}

#speakerView .video-wrapper {
  height: var(--speakerViewHeight);
  flex: 0 0 100%;
}

.controls-wrapper {
  width: calc(100% - 40px);
  padding: 20px 0;
  position: absolute;
  bottom: 0;
}

.controls {
  background-color: var(--blue);
  opacity: .5;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin: 0 10px;
  position: relative;
}

.control-active {
  -webkit-box-shadow: 0px 1px 10px var(--blue);
  box-shadow: 0px 1px 10px var(--blue);
  opacity: 1 !important;
}

#leaveBtn {
  background-color: var(--red) !important;
}

.confirm-btns-wrapper {
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
  width: 100%;
  margin: 20px 0 10px;
}

.leave-btn {
  margin: 0;
  display: inline !important;
}

.confirm-btn {
  background-color: var(--red);
}

.chat-notif {
  background-color: var(--red);
  padding: 5px;
  font-weight: 600;
  position: absolute;
  top: 0;
}

#streamErrorOverlay {
  z-index: 1;
  visibility: hidden;
  position: fixed;
  top: -100px;
  left: 50%;
  transform: translateX(-50%);
}

.stream-error-overlay-change {
  visibility: visible !important;
  top: -20px !important;
}

.stream-error-overlay {
  background-color: var(--chatMessageRemoteColor);
  color: var(--orange);
  text-align: center;
  border-radius: 0 0 6px 6px;
  padding: 30px 20px 10px;
}

@media (width <= 1024px) {
  #roomView {
    padding: 0;
  }

  .main-view-change {
    width: 100% !important;
    margin-left: 0 !important;
  }

  .main-view-chat-change {
    width: 100% !important;
    margin-right: 0 !important;
  }

  #chatView {
    right: 0;
  }

  #chatView .sidebar-title-wrapper {
    padding: 0 20px;
  }

  #chatView, #chatHist, #chatForm {
    width: 100%;
  }

  #chatForm textarea {
    max-width: unset;
  }

  #sideBar .sidebar-title-wrapper {
    position: relative;
    background-color: #0000 !important;
  }

  .controls-wrapper {
    width: 100%;
  }
}

@media (width <= 768px) {
  .user-wrapper {
    padding-right: 10px;
  }

  .controls-wrapper {
    z-index: 2;
  }

  #settingsInvite, #settingsPass {
    align-self: baseline;
  }

  #videoGrid {
    gap: 0;
  }

  .video {
    border-radius: 0;
  }

  .name-tag-center {
    font-size: 1em;
  }

  .controls {
    margin: 0 5px;
  }

  #myVideo {
    width: 100px;
    height: 177px;
  }
}

@media (hover: hover) {
  .submit-btn:hover, .submit-btn:focus {
    -webkit-box-shadow: 0px 1px 10px var(--buttonColor);
    box-shadow: 0px 1px 10px var(--buttonColor);
  }

  .error-btn:hover, .error-btn:focus {
    -webkit-box-shadow: 0px 1px 10px var(--errColor);
    box-shadow: 0px 1px 10px var(--errColor);
  }

  #forgotPass:hover, #forgotPass:focus {
    color: var(--blue);
    text-decoration: underline;
  }

  #settingsBtn:hover, #settingsBtn:focus {
    filter: brightness(.5);
  }

  .settings-item:hover, .settings-item:focus {
    color: var(--blue);
    background-color: var(--chatMessageColor);
  }

  #openDrawerBtn:hover {
    box-shadow: 0 0 5px #e1d4c21c;
  }

  #openDrawerBtn:hover svg path {
    fill: var(--textLightColor);
  }

  #closeDrawerBtn:hover svg polyline {
    stroke: var(--blue);
    stroke-width: 4px;
  }

  .sidebar-btns:hover {
    background-color: var(--bckgdColor);
    -webkit-box-shadow: 0px 1px 5px var(--bckgdColor);
    box-shadow: 0px 1px 5px var(--bckgdColor);
  }

  .room-item:hover {
    background-color: var(--blue);
    color: var(--textColor);
  }

  .room-item:hover .notif {
    background-color: var(--bckgdColor);
    color: var(--blue);
  }

  #closeChatBtn:hover svg polyline {
    stroke: var(--textLightColor);
    stroke-width: 4px;
  }

  #uploadBtn:hover svg path, #removeFileBtn:hover svg path {
    fill: var(--blue);
  }

  .hyperlinks:hover {
    text-decoration: none;
  }

  .controls:hover {
    opacity: 1;
  }

  .full-screen-btn:hover {
    transform: scale(1.1);
  }

  #leaveBtn:hover, #leaveBtn:focus, .confirm-btn:hover, .confirm-btn:focus {
    -webkit-box-shadow: 0px 1px 10px var(--red) !important;
    box-shadow: 0px 1px 10px var(--red) !important;
    background-color: var(--red) !important;
  }

  .file-received:hover, .custom-input-wrapper:hover .checkmark {
    background-color: var(--chatMessageColor);
  }
}

@supports (backdrop-filter: none) {
  .overlay {
    background-color: var(--overlayColor);
    backdrop-filter: blur(15px);
  }

  #box {
    backdrop-filter: blur(10px);
    background-color: #57ded84d;
  }

  .video-wrapper .please-wait {
    background-color: var(--overlayColor);
    backdrop-filter: blur(5px);
  }

  @media (width <= 1024px) {
    #sideBar {
      backdrop-filter: blur(20px);
      background-color: #47474180;
    }
  }
}
/*# sourceMappingURL=index.f08dc59e.css.map */
