/* ------- BEGIN FONTS */
@font-face {
    font-family: 'Gill Sans';
    src: url(https://meowapp.b-cdn.net/static/fonts/GillSans-Regular.ttf) format("truetype");
    font-weight: 400;
}

@font-face {
    font-family: 'Gill Sans';
    src: url(https://meowapp.b-cdn.net/static/fonts/GillSans-Light.ttf) format("truetype");
    font-weight: 100;
}

@font-face {
    font-family: 'Gill Sans';
    src: url(https://meowapp.b-cdn.net/static/fonts/GillSans-SemiBold.ttf) format("truetype");
    font-weight: 600;
}

/* ------- END FONTS */
/*  ------- BEGIN GLOBALS */
:root {
    /* GLOBAL COLORS */
    --bckgdColor: #181816;
    --offBckgdColor: #111;
    --overlayColor: rgb(30 30 30 / 70%);
    --secondaryOverlayColor: rgba(71, 71, 65, 0.502);
    --sideBarColor: #474741;
    --buttonColor: #57ded8;
    --buttonTextColor: #000;
    --textColor: #000;
    --textLightColor: #faebd7;
    --inputColor: #474741;
    --inputTextColor: #faebd7;
    --placeHolderColor: #9f9283;
    --successColor: #7bff84;
    --blue: #57ded8;
    --red: #f97979;
    --orange: #deaf57;
    --errColor: #f97979;
    --chatMessageColor: #152a29;
    --chatMessageRemoteColor: #563030;
    --headerHeight: 50px;
    --mobilePadding: 12px;
    --sideBarWidth: 0px;
    --chatViewWidth: 0px;
    --speakerViewWidth: 0px;
    --speakerViewHeight: 0px;
    --vh: 1vh;
}

*,
:after,
:before {
    box-sizing: border-box;
    -ms-overflow-style: none;
    /* IE and Edge */
    /* TO REMOVE SCROLLBAR */
    scrollbar-width: none;
    /* Firefox */
}

/* BEGIN REMOVE SCROLLBARS */
*::-webkit-scrollbar {
    display: none;
}

/* END REMOVE SCROLLBARS */

.flex-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.flex-center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.flex-col {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.no-display {
    display: none !important;
}

.hidden {
    visibility: hidden !important;
}

.no-scroll {
    overflow: hidden;
}

.no-pointers {
    pointer-events: none !important;
}

.ease-out {
    -o-transition: 0.3s ease-out;
    -moz-transition: 0.3s ease-out;
    -webkit-transition: 0.3s ease-out;
    transition: 0.3s ease-out;
}

.cubic-bezier {
    -o-transition: .3s cubic-bezier(0.57, -0.21, 0, 0.74);
    -moz-transition: .3s cubic-bezier(0.57, -0.21, 0, 0.74);
    -webkit-transition: .3s cubic-bezier(0.57, -0.21, 0, 0.74);
    transition: .3s cubic-bezier(0.57, -0.21, 0, 0.74);
}

.transform-y100 {
    -o-transform: translateY(100%);
    -moz-transform: translateY(100%);
    -webkit-transform: translateY(100%);
    -ms-transform: translateY(100%);
    transform: translateY(100%);
}

.transform-y-100 {
    -o-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
}

.transform-x-100 {
    -o-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
}

.transform-x100 {
    -o-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    -ms-transform: translateX(100%);
    transform: translateX(100%);
}

.video-transform {
    -o-transform: translate(var(--offsetX), var(--offsetY));
    -moz-transform: translate(var(--offsetX), var(--offsetY));
    -webkit-transform: translate(var(--offsetX), var(--offsetY));
    -ms-transform: translate(var(--offsetX), var(--offsetY));
    transform: translate(var(--offsetX), var(--offsetY));
}

.no-opacity {
    opacity: 0 !important;
}

.text-center {
    text-align: center;
}

.read-text {
    line-height: 1.5em;
    font-size: 1em;
}

.no-margin {
    margin: 0 !important;
}

.margin-1 {
    margin: 1em !important;
}

.error {
    color: var(--errColor) !important;
}

.bckg {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.btns {
    border: none;
    outline: none;
    background: none;
    padding: 0;
    font-family: "Gill Sans", "Gill Sans MT", "Myriad Pro", "DejaVu Sans Condensed", Helvetica, Arial, "sans-serif";
    -ms-flex-negative: 0;
    flex-shrink: 0;
    cursor: pointer;
}

button:disabled {
    opacity: 0.8;
    background-color: var(--buttonColor) !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    color: var(--buttonTextColor) !important;
    cursor: default !important;
}

/* ------- BEGIN BUTTON SPINNER LOADER */
.spinner,
.spinner:before {
    border-radius: 50%;
}

.spinner {
    color: var(--buttonTextColor);
    font-size: 22px;
    text-indent: -99999px;
    margin: 0px auto;
    position: relative;
    width: 20px;
    height: 20px;
    -webkit-box-shadow: inset 0 0 0 2px;
    box-shadow: inset 0 0 0 2px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    overflow: hidden;
}

.spinner:before {
    position: absolute;
    content: "";
}

.spinner:before {
    width: 10.4px;
    height: 20.4px;
    background: var(--buttonColor);
    border-radius: 20.4px 0 0 20.4px;
    top: -0.2px;
    left: -0.2px;
    -webkit-transform-origin: 10.4px 10.2px;
    transform-origin: 10.4px 10.2px;
    -webkit-animation: loading 2s infinite ease 1.5s;
    animation: loading 1s infinite linear;
}

@-webkit-keyframes loading {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes loading {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

/* ------- END BUTTON SPINNER LOADER */
/* ------- BEGIN PLEASE WAIT LOADER */
.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: var(--blue);
    -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
    left: 8px;
    -webkit-animation: lds-ellipsis1 0.6s infinite;
    animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
    left: 8px;
    -webkit-animation: lds-ellipsis2 0.6s infinite;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
    left: 32px;
    -webkit-animation: lds-ellipsis2 0.6s infinite;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
    left: 56px;
    -webkit-animation: lds-ellipsis3 0.6s infinite;
    animation: lds-ellipsis3 0.6s infinite;
}

@-webkit-keyframes lds-ellipsis1 {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@-webkit-keyframes lds-ellipsis3 {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
}

@-webkit-keyframes lds-ellipsis2 {
    0% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }

    100% {
        -webkit-transform: translate(24px, 0);
        transform: translate(24px, 0);
    }
}

@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}

@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(24px, 0);
    }
}

.please-wait {
    width: 100%;
}

.please-wait .read-text {
    text-align: center;
    color: var(--blue);
    margin: 0;
}

/* ------- END PLEASE WAIT LOADER */
html,
body {
    margin: 0;
    padding: 0;
    width: 100%;
    -webkt-scroll-behavior: smooth;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
}

html {
    background-color: var(--bckgdColor);
    -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    text-size-adjust: 100%;
    font-family: "Gill Sans", "Gill Sans MT", "Myriad Pro", "DejaVu Sans Condensed", Helvetica, Arial, "sans-serif";
}

/*  ------- END GLOBALS */
/* ------- BEGIN 404 PAGE */
#infoWrapper {
    width: 100vw;
    height: 100vh;
}

#infoWrapper img {
    max-width: 200px;
    width: 80%;
}

#boxWrapper {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
}

#box {
    background-color: var(--blue);
    border-radius: 6px;
    padding: 20px;
    width: calc(100% - 40px);
    min-height: 1px;
    max-width: 420px;
    text-align: center;
    opacity: 0;
    display: none;
}

#box a:link,
#box a:visited {
    text-decoration: underline;
    font-weight: 700;
    color: var(--offBckgdColor);
}

#box h1,
#box h2,
#box p {
    color: var(--textColor);
}

/* ------- END 404 PAGE */

/* ------- BEGIN OVERLAY */
#resultOverlay {
    z-index: 999;
}

#fileDropOverlay {
    border: 2px solid var(--blue);
}

#fileDropOverlay p {
    font-size: 2em;
    color: var(--blue);
}

.overlay {
    width: 100%;
    height: calc(100 * var(--vh));
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgb(30 30 30 / 90%);
    cursor: pointer;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
}

.overlay-change {
    visibility: visible !important;
    opacity: 1 !important;
}

.overlay-box {
    height: auto;
    max-height: 500px;
    transform: scale(0);
    max-width: 400px;
    width: 95%;
    padding-bottom: 20px;
    background-color: var(--offBckgdColor);
    color: var(--textLightColor);
    border-radius: 6px;
    overflow-y: scroll;
    cursor: default;
}

.overlay-box-change {
    transform: scale(1);
}

.overlay-box h1 {
    text-align: center;
}

.overlay-box p {
    text-align: center;
    margin: 2em 0;
}

.overlay-content {
    padding: 0 20px;
}

.overlay-content-sticky-header {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    width: 100%;
    background-color: var(--offBckgdColor);
    padding-bottom: 20px;
    z-index: 1;
}

/* ------- END OVERLAY */
/* ------- BEGIN INPUT TYPES */

.input-fields {
    border: none;
    outline: none;
    border-radius: 6px;
    width: 100%;
    max-width: 320px;
    height: 40px;
    color: var(--inputTextColor);
    background-color: var(--inputColor);
    margin: 10px 0 0 0;
    padding: 10px;
    font-family: "Gill Sans", "Gill Sans MT", "Myriad Pro", "DejaVu Sans Condensed", Helvetica, Arial, "sans-serif";
}

.input-readonly {
    background-color: var(--offBckgdColor) !important;
    color: var(--placeHolderColor);
    cursor: default;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: none;
    -webkit-text-fill-color: var(--inputTextColor);
    -webkit-box-shadow: 0 0 0px 1000px var(--inputColor) inset;
    box-shadow: 0 0 0px 1000px var(--inputColor) inset;
    -o-transition: background-color 5000s ease-in-out 0s;
    -moz-transition: background-color 5000s ease-in-out 0s;
    -webkit-transition: background-color 5000s ease-in-out 0s;
    transition: background-color 5000s ease-in-out 0s;
}

select {
    /* Remove gloss on safari dropdown and add custom transparent arrows */
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMC4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCA0LjkgMTAiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDQuOSAxMDsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4NCgkuc3Qwe2ZpbGw6I2ZmZmZmZjt9DQo8L3N0eWxlPg0KPHRpdGxlPmFycm93czwvdGl0bGU+DQo8cG9seWdvbiBjbGFzcz0ic3QwIiBwb2ludHM9IjEuNCw0LjcgMi41LDMuMiAzLjUsNC43ICIvPg0KPHBvbHlnb24gY2xhc3M9InN0MCIgcG9pbnRzPSIzLjUsNS4zIDIuNSw2LjggMS40LDUuMyAiLz4NCjwvc3ZnPg==) no-repeat 95% 50%;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
}

select:required:invalid {
    color: gray;
}

option {
    color: white;
}

textarea {
    resize: none;
    min-height: 35px !important;
}

::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--placeHolderColor);
    opacity: 1;
    /* Firefox */
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: var(--placeHolderColor);
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: var(--placeHolderColor);
}

.submit-btn {
    display: inline-block;
    border: none;
    outline: none;
    min-width: 110px;
    height: 34px;
    font-size: 1em;
    font-weight: 600;
    background-color: var(--buttonColor);
    color: var(--buttonTextColor);
    padding: 5px 20px;
    border-radius: 6px;
    margin: 10px auto;
}

.error-btn {
    background-color: var(--errColor) !important;
}

/* ------- END INPUT TYPES */
/* ---------- BEGIN LOGIN */
.login-container {
    width: 100%;
    min-height: calc(100 * var(--vh));
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.logo {
    background-image: url(https://meowapp.b-cdn.net/static/images/logo.png);
    width: 100px;
    height: 100px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin: 0 auto;
}

.login-overlay {
    width: calc(100% - var(--mobilePadding));
    max-height: 700px;
    padding: 20px;
}

.app-title {
    color: var(--textLightColor);
    margin-bottom: 0;
}

.app-subtitle {
    color: var(--placeHolderColor);
}

#loginForm {
    width: 100%;
    max-width: 200px;
}

#signupForm {
    width: 100%;
    max-width: 260px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

#signupBtn {
    width: 100%;
    margin: 0 auto;
}

.pass-wrapper {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
}

#loginBtn,
#forgotBtn {
    width: 100%;
    margin: 10px 0;
}

.show-btn {
    border: none;
    outline: none;
    background-color: var(--inputColor);
    color: var(--inputTextColor);
    font-size: 0.8em;
    font-weight: 100;
    height: 40px;
    margin-left: -6px;
    margin-top: 10px;
    border-radius: 0px 6px 6px 0px;
    padding: 0 10px;
}

#forgotPass {
    text-align: center;
    color: var(--textLightColor);
    margin: 0;
    cursor: pointer;
}

.success-logout {
    height: 40px;
    padding: 5px 10px;
    border-radius: 5px;
    text-align: center;
    margin: 20px 0;
    color: var(--successColor);
    background-color: var(--chatMessageColor);
}

/* ---------- END LOGIN */
/* ---------- BEGIN SETTINGS */
/* SETTINGS OVERLAY */
#settingsOverlay .overlay-box {
    width: calc(100% - var(--mobilePadding));
}

.settings-item {
    width: 100%;
    height: 50px;
    padding: 5px 15px;
    margin: 5px 0;
    border-radius: 5px;
    background-color: var(--bckgdColor);
    text-align: center;
    text-decoration: none;
    color: var(--textLightColor);
    cursor: pointer;
}

/* SETTINGS VIEW */
#settingsView {
    margin: 0 auto;
    -webkit-box-align: unset;
    -ms-flex-align: unset;
    align-items: unset;
    padding-top: 50px;
}

#exitSettingsBtn {
    position: absolute;
    top: 0;
    margin: 20px auto;
}

#settingsView h1,
#settingsView h2 {
    color: var(--textLightColor);
}

#settingsView form {
    display: block;
    width: 100%;
    max-width: 290px;
    margin-top: 20px;
}

#settingsWrapper {
    padding-bottom: 100px;
}

#settingsWrapper p {
    color: var(--textLightColor);
    max-width: 320px;
}

.setting-page {
    width: calc(100% - var(--mobilePadding));
}

/* CHANGE PASSWORD */
#changePassForm,
#newPassCriteria {
    width: 100%;
    max-width: 290px;
}

#newPassCriteria {
    margin: 10px 0;
}

#newPassCriteria p {
    color: var(--textLightColor);
}

#changePassForm label {
    font-size: 1em;
}

#resetPassBtn,
#inviteBtn {
    width: 100%;
    margin: 10px 0;
}

#resetPassBtn {
    margin-top: 0;
}

.pass-criteria-wrapper {
    padding: 5px 10px;
    background-color: var(--chatMessageColor);
    border-radius: 6px;
    font-size: 0.8em;
}

.pass-criteria {
    margin: 10px 0;
    font-weight: 100;
}

.pass-criteria span {
    color: var(--placeHolderColor);
}

.pass-criteria-success {
    color: var(--blue) !important;
    font-weight: 400 !important;
}

.pass-criteria-text {
    margin-left: 10px;
}

.reset-pass:disabled {
    background-color: var(--sideBarColor) !important;
}

/* ---------- END SETTINGS */

/* ---------- BEGIN FIXED SIDEBARS */
#fixedContainer {
    width: 100%;
    position: fixed;
    top: 0px;
    z-index: 3;
}

/* BEGIN LEFT SIDEBAR */
#sideBar {
    width: 280px;
    background-color: var(--sideBarColor);
    left: -30px;
    -webkit-box-shadow: 1px 1px 5px #000000a1;
    box-shadow: 1px 1px 5px #000000a1;
    padding: 50px 20px 0 50px;
}

#sideBar,
#chatView {
    height: calc(100 * var(--vh));
    position: absolute;
    top: 0;
    z-index: 1;
    overflow-x: hidden;
    overflow-y: scroll;
}

#header {
    width: 100%;
    height: 50px;
    background-color: var(--bckgdColor);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-shadow: 0px 1px 12px #000;
    box-shadow: 0px 1px 12px #000;
    color: var(--blue);
    padding: 0 10px 0 40px;
}

#userName {
    text-transform: uppercase;
    font-weight: 600;
    text-align: right;
}

#closeDrawerBtn {
    margin-left: 10px;
}

#settingsBtn {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-image: url(https://meowapp.b-cdn.net/static/images/logo.png);
    margin-right: 20px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    cursor: pointer;
}


#openDrawerBtn {
    position: absolute;
    left: 20px;
    top: 15px;
    background-color: var(--bckgdColor);
    border: 1px solid var(--blue);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    z-index: 1;
}

.sidebar-title-wrapper {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
}

#sideBar .sidebar-title-wrapper {
    background-color: var(--sideBarColor);
}

#sideBar h2 {
    color: var(--textLightColor);
}

.sidebar-section,
.sidebar-title-wrapper {
    border-bottom: 1px solid var(--bckgdColor);
}

.sidebar-section-users {
    padding-bottom: 20px;
}

.sidebar-section-flex {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.sidebar-title {
    color: var(--textLightColor);
}

.sidebar-btns {
    width: 40px;
    height: 40px;
    background-color: #18181680;
    border-radius: 50%;
}

#addRoomBtn svg {
    position: relative;
    top: 1px;
}

#roomForm {
    margin-top: 20px;
}

#roomSubmitBtn {
    width: 100%;
}

.room-item {
    text-align: center;
    color: var(--blue);
    background-color: var(--bckgdColor);
    height: 40px;
    margin: 10px 0;
    border-radius: 6px;
    padding: 0 10px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    cursor: pointer;
}

.room-item-name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.room-item-active {
    background-color: var(--blue) !important;
    color: var(--textColor);
    -webkit-box-shadow: 0px 1px 10px var(--blue);
    box-shadow: 0px 1px 10px var(--blue);
    cursor: default !important;
}

.notif {
    min-width: 25px;
    height: 25px;
    padding: 0 5px;
    background-color: var(--blue);
    border-radius: 20px;
}

/* BEGIN SELECT USERS FORM */
#addUsersRoomName {
    font-weight: 700;
    text-decoration: underline;
}

#addUsersForm {
    width: 100%;
}

.select-users-wrapper {
    margin: 10px 0;
}

.custom-input-wrapper {
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-bottom: 1px solid var(--inputColor);
    padding-bottom: 10px;
    margin: 10px 0;
}


.custom-input-wrapper input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
    height: 1px;
    width: 1px;
}

.checkmark {
    height: 25px;
    width: 25px;
    background-color: var(--inputColor);
    border-radius: 50%;
}

.checkmark-value {
    font-size: 1em;
    font-weight: 700;
    color: var(--inputColor);
    margin-left: 10px;
}

.custom-input-wrapper input:checked~.checkmark {
    background-color: var(--blue) !important;
}

.custom-input-wrapper input:checked~.checkmark-value {
    color: var(--blue) !important;
}

#addUsersBtn {
    width: 100%;
}

#addUserBtn svg {
    position: relative;
    left: 1px;
}

/* END SELECT USERS FORM */
.user-wrapper {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    height: 30px;
}

.pulse {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: var(--blue);
    -webkit-animation: pulse 0.8s ease-in-out 0s infinite alternate;
    animation: pulse 0.8s ease-in-out 0s infinite alternate;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

@-webkit-keyframes pulse {
    from {
        opacity: 1
    }

    to {
        opacity: 0.3;
    }
}

@keyframes pulse {
    from {
        opacity: 1
    }

    to {
        opacity: 0.3;
    }
}

.user-item {
    text-transform: capitalize;
    color: var(--placeHolderColor);
}

.user-item-active {
    color: var(--blue) !important;
}

/* BEGIN CHAT VIEW */
#chatView {
    width: 350px;
    background-color: var(--offBckgdColor);
    right: -30px;
    -webkit-box-shadow: -1px 1px 5px #000000a1;
    box-shadow: -1px 1px 5px #000000a1;
}

#chatView .sidebar-title-wrapper {
    padding: 0 50px 0 20px;
    background-color: var(--offBckgdColor);
}

#closeChatBtn svg {
    -o-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

#chatForm {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    left: 0;
    width: calc(100% - 30px);
    overflow: hidden;
}

.chat-form-wrapper {
    background-color: var(--sideBarColor);
    padding: 5px;
    position: relative;
    z-index: 1;
}

#chatInput {
    background-color: var(--bckgdColor);
    border-radius: 10px;
    height: 35px;
    max-height: calc(75 * var(--vh));
}

#chatForm textarea {
    margin: 0;
}

#uploadBtn,
#submitBtn {
    width: 30px;
    height: 32px;
    -ms-flex-item-align: end;
    align-self: flex-end;
}

#submitBtn {
    margin-right: 5px;
}

#submitBtn:disabled {
    background-color: transparent !important;
}

#submitBtn .spinner {
    color: var(--blue);
}

#submitBtn .spinner:before {
    background-color: var(--sideBarColor);
}

.submit-btn-active span svg path {
    fill: var(--blue);
}

/* BEGIN FILE UPLOAD */
#fileErrorWrapper {
    background-color: var(--bckgdColor);
    padding: 10px 20px;
    position: relative;
    transform: translateY(100%);
}

.file-error-change {
    transform: none !important;
}

#fileReveal {
    padding: 20px;
    background-color: var(--bckgdColor);
    position: relative;
    transform: translateY(100%);
    z-index: 1;
}

.file-reveal-change {
    transform: none !important;
}

.file-reveal-box {
    position: relative;
    border: 1px solid var(--sideBarColor);
    border-radius: 10px;
    padding: 20px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.file-info {
    overflow: hidden;
}

#fileName {
    color: var(--textLightColor) !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

#fileSize {
    color: var(--placeHolderColor);
    font-size: 0.8em;
}

#removeFileBtn {
    position: absolute;
    top: -8px;
    right: -8px;
}

#sendFileBtn {
    position: absolute;
    width: 25px;
    bottom: 5px;
    right: 5px;
}

#sendFileBtn .spinner {
    color: var(--successColor);
}

#sendFileBtn .spinner:before {
    background-color: var(--bckgdColor);
}

#sendFileBtn:disabled {
    background-color: transparent !important;
}

/* BEGIN FILE UPLOAD PROGRESS */
#progressWrapper {
    padding-top: 10px;
}

.progress-user-wrapper {
    width: 100%;
    gap: 10px;
    justify-content: left;
}

.progress-bar {
    width: 100%;
    height: 5px;
    background-color: var(--secondaryOverlayColor);
    border-radius: 100vmax;
    overflow: hidden;
}

.progress {
    width: 0%;
    height: 5px;
    background-color: var(--successColor);
}

.progress-perc {
    color: var(--blue) !important;
}

.progress-user-wrapper p {
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    color: var(--successColor);
}

.progress-user {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 50%;
    text-align: left;
    margin: 5px 0;
    color: var(--placeHolderColor) !important;
}

.progress-user-wrapper .error {
    font-size: .8em;
}

/* END FILE UPLOAD PROGRESS */
.file-received:link,
.file-received:visited {
    color: var(--textLightColor);
}

.file-received {
    position: relative;
    width: 100%;
    background-color: var(--offBckgdColor);
    border-radius: 10px;
    margin: 10px 0;
    padding: 20px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-shadow: 0px 1px 5px var(--offBckgdColor);
    box-shadow: 0px 1px 5px var(--offBckgdColor);
    text-decoration: none;
}

.file-icon {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

.file-name {
    color: var(--orange);
    font-weight: 400;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.file-size {
    font-size: 0.8em;
    color: var(--placeHolderColor);
}

/* END FILE UPLOAD */
#chatHist {
    width: calc(100% - 30px);
    min-height: calc(calc(100 * var(--vh)) - 96px);
    padding: 5px 25px;
}

.chat-message {
    position: relative;
    border-radius: 10px;
    padding: 10px 10px 20px;
    margin-bottom: 10px;
}

.chat-message-remote:before,
.chat-message-current:before {
    position: absolute;
    top: calc(100% - 35px);
    right: calc(100% - 10px);
    content: '';
    height: 30px;
    width: 30px;
    display: block;
    margin-left: 100px;
    -o-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
}

.chat-message-current:before {
    background: radial-gradient(circle at 100% 0, transparent 30px, #152a29 0);
}

.chat-message-remote:before {
    background: radial-gradient(circle at 100% 0, transparent 30px, #563030 0);
}

.chat-message-remote {
    background-color: var(--chatMessageRemoteColor);
}

.chat-message-current {
    background-color: var(--chatMessageColor);
}

.chat-user {
    margin: 0;
    text-transform: capitalize;
    font-weight: 100;
    word-break: break-word;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

.chat-text {
    margin: 0 0 10px;
    color: var(--textLightColor) !important;
    word-break: break-word;
}

.chat-message-current .chat-user {
    color: var(--blue) !important;
}

.chat-message-remote .chat-user {
    color: var(--red) !important;
}

.msg-time {
    color: var(--placeHolderColor);
    font-size: 10px;
    float: right;
    -ms-flex-item-align: end;
    align-self: flex-end;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

.user-typing {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    min-width: 65px;
}

.user-typing .lds-ellipsis {
    width: 0;
    height: 20px;
    -o-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -webkit-transform: scale(0.5);
    -ms-transform: scale(0.5);
    transform: scale(0.5);
}

.user-typing .lds-ellipsis div {
    top: 19px;
    background-color: var(--textLightColor) !important;
}

.new-day {
    font-size: 0.8em;
    color: var(--placeHolderColor);
}

.hyperlinks:link,
.hyperlinks:visited {
    color: var(--orange);
    font-weight: 400;
    word-break: break-word;
    cursor: pointer;
}

/* END CHAT VIEW */
/* ---------- END LEFT SIDE BAR */
/* ---------- BEGIN CONTENT */
/* BEGIN MAIN VIEW */

.page {
    position: relative;
    padding: 10px;
    min-height: calc(100 * var(--vh));
}

.main-page {
    position: fixed;
    width: 100%;
}

.main-view-change {
    --sideBarWidth: 250px !important;
    width: calc(100% - calc(var(--sideBarWidth) + var(--chatViewWidth))) !important;
    width: calc(100% - 250px);
    margin-left: 250px !important;
}

.main-view-chat-change {
    --chatViewWidth: 320px !important;
    width: calc(100% - calc(var(--sideBarWidth) + var(--chatViewWidth))) !important;
    margin-right: 320px !important;
}

/* END MAIN VIEW */
/* BEGIN WELCOME VIEW */
#welcomeView {
    width: 100%;
    color: var(--textLightColor);
    max-height: 700px;
}

#welcomeView h1 {
    margin-bottom: 0;
}

#getStartedText {
    color: var(--blue);
}

/* END WELCOME VIEW */
/* BEGIN ROOM VIEW */
#roomView {
    position: relative;
    width: 100%;
    height: calc(calc(100 * var(--vh) - 20px));
    -o-transition: width .3s cubic-bezier(0.57, -0.21, 0, 0.74), margin .3s cubic-bezier(0.57, -0.21, 0, 0.74);
    -moz-transition: width .3s cubic-bezier(0.57, -0.21, 0, 0.74), margin .3s cubic-bezier(0.57, -0.21, 0, 0.74);
    -webkit-transition: width .3s cubic-bezier(0.57, -0.21, 0, 0.74), margin .3s cubic-bezier(0.57, -0.21, 0, 0.74);
    transition: width .3s cubic-bezier(0.57, -0.21, 0, 0.74), margin .3s cubic-bezier(0.57, -0.21, 0, 0.74);
}

.view-btn {
    width: 30px;
    height: 30px;
    position: absolute;
    right: 10px;
    top: 10px;
    opacity: 1;
    z-index: 2;
}

.view-btn[data-view="mosaic"] {
    background-color: var(--bckgdColor);
}

.view-btn[data-view="speaker"] {
    background-color: var(--bckgdColor);
    padding: 2px;
}


#videoGrid {
    position: relative;
    top: 50%;
    margin: auto;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 10px;
}

.speaker-grid-view {
    height: 100px !important;
    top: unset !important;
    flex-wrap: nowrap !important;
}

.video-grid-overflow {
    overflow-x: scroll !important;
    overflow-y: hidden !important;
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
}

#myVideo {
    position: absolute;
    width: 200px;
    height: 112.5px;
    bottom: 0px;
    right: 0px;
    border: 2px solid var(--bckgdColor);
    cursor: move;
    z-index: 1;
    -o-object-fit: cover;
    object-fit: cover;
}


.my-video-change {
    position: relative !important;
    top: unset !important;
    right: unset !important;
    border: none !important;
    cursor: pointer !important;
}

.video-wrapper {
    position: relative;
}

.video {
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 6px;
    width: 100%;
    height: 100%;
}

.video-info {
    position: absolute;
    bottom: 0px;
    left: 0px;
    background-color: #47474191;
    padding: 5px;
    border-radius: 0px 5px 0px 0px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 5px;
}

.name-tag {
    color: #fff;
    text-transform: capitalize;
    font-size: 0.8em;
    pointer-events: none;
}

.mute-icon {
    pointer-events: none;
}

.name-tag-center {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-size: 2em;
    color: var(--sideBarColor);
    text-transform: capitalize;
    text-align: center;
    pointer-events: none;
}

/* BEGIN RECONNECTING LOADER */
.lds-spinner {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-spinner div {
    transform-origin: 40px 40px;
    animation: lds-spinner 1.2s linear infinite;
}

.lds-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 37px;
    width: 6px;
    height: 18px;
    border-radius: 20%;
    background: var(--blue);
}

.lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
}

.lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
}

.lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
}

.lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
}

.lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
}

.lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
}

.lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
}

.lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
}

.lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
}

.lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
}

.lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
}

.lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
}

@keyframes lds-spinner {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.reconnecting-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80px;
    height: 80px;
}

.speaker-grid-view .reconnecting-loader {
    transform: translate(-50%, -50%) scale(0.5);
}

/* END RECONNECTING LOADER */

.speaker-grid-view .name-tag-center {
    font-size: 1em;
}

.speaker-grid-view .video {
    width: 178px !important;
    height: 100% !important;
    cursor: pointer !important;
}

.active-speaker-view .video {
    border: 1px solid var(--blue);
}

#speakerView {
    width: var(--speakerViewWidth);
    position: relative;
    margin: auto;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding-top: 10px;
}

#speakerView .video-wrapper {
    flex: 0 0 100%;
    height: var(--speakerViewHeight);
}

.controls-wrapper {
    position: absolute;
    width: calc(100% - 40px);
    padding: 20px 0;
    bottom: 0;
}

.controls {
    position: relative;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: var(--blue);
    margin: 0 10px;
    opacity: 0.5;
}

.control-active {
    opacity: 1 !important;
    -webkit-box-shadow: 0px 1px 10px var(--blue);
    box-shadow: 0px 1px 10px var(--blue);
}

#leaveBtn {
    background-color: var(--red) !important;
}

.confirm-btns-wrapper {
    width: 100%;
    margin: 20px 0 10px;
    -webkit-box-pack: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
}

.leave-btn {
    display: inline !important;
    margin: 0;
}

.confirm-btn {
    background-color: var(--red);
}

.chat-notif {
    position: absolute;
    top: 0;
    padding: 5px;
    background-color: var(--red);
    font-weight: 600;
}

#streamErrorOverlay {
    position: fixed;
    top: -100px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: 1;
    visibility: hidden;
}

.stream-error-overlay-change {
    visibility: visible !important;
    top: -20px !important;
}

.stream-error-overlay {
    background-color: var(--chatMessageRemoteColor);
    border-radius: 0px 0px 6px 6px;
    padding: 30px 20px 10px;
    color: var(--orange);
    text-align: center;
}

/* END ROOM VIEW */
/* ---------- END CONTENT */
@media(max-width:1024px) {
    #roomView {
        padding: 0;
    }

    .main-view-change {
        margin-left: 0px !important;
        width: 100% !important;
    }

    .main-view-chat-change {
        margin-right: 0px !important;
        width: 100% !important;
    }

    #chatView {
        right: 0;
    }

    #chatView .sidebar-title-wrapper {
        padding: 0 20px 0 20px;
    }

    #chatView,
    #chatHist,
    #chatForm {
        width: 100%;
    }

    #chatForm textarea {
        max-width: unset;
    }

    #sideBar .sidebar-title-wrapper {
        position: relative;
        background-color: transparent !important;
    }

    .controls-wrapper {
        width: 100%;
    }

}

@media(max-width:768px) {
    .user-wrapper {
        padding-right: 10px;
    }

    .controls-wrapper {
        z-index: 2;
    }

    #settingsInvite,
    #settingsPass {
        -ms-flex-item-align: baseline;
        align-self: baseline;
    }

    #videoGrid {
        gap: 0;
    }

    .video {
        border-radius: 0px;
    }

    .name-tag-center {
        font-size: 1em;
    }

    .controls {
        margin: 0 5px;
    }

    #myVideo {
        width: 100px;
        height: 177px;
    }
}

@media(hover:hover) {

    .submit-btn:hover,
    .submit-btn:focus {
        -webkit-box-shadow: 0px 1px 10px var(--buttonColor);
        box-shadow: 0px 1px 10px var(--buttonColor);
    }

    .error-btn:hover,
    .error-btn:focus {
        -webkit-box-shadow: 0px 1px 10px var(--errColor);
        box-shadow: 0px 1px 10px var(--errColor);
    }

    #forgotPass:hover,
    #forgotPass:focus {
        text-decoration: underline;
        color: var(--blue);
    }

    #settingsBtn:hover,
    #settingsBtn:focus {
        filter: brightness(0.5);
    }

    .settings-item:hover,
    .settings-item:focus {
        color: var(--blue);
        background-color: var(--chatMessageColor);
    }

    #openDrawerBtn:hover {
        -webkit-box-shadow: 0px 0px 5px #e1d4c21c;
        box-shadow: 0px 0px 5px #e1d4c21c;
    }

    #openDrawerBtn:hover svg path {
        fill: var(--textLightColor);
    }

    #closeDrawerBtn:hover svg polyline {
        stroke: var(--blue);
        stroke-width: 4px;
    }

    .sidebar-btns:hover {
        background-color: var(--bckgdColor);
        -webkit-box-shadow: 0px 1px 5px var(--bckgdColor);
        box-shadow: 0px 1px 5px var(--bckgdColor);
    }

    .room-item:hover {
        background-color: var(--blue);
        color: var(--textColor);
    }

    .room-item:hover .notif {
        background-color: var(--bckgdColor);
        color: var(--blue);
    }

    #closeChatBtn:hover svg polyline {
        stroke: var(--textLightColor);
        stroke-width: 4px;
    }

    #uploadBtn:hover svg path {
        fill: var(--blue);
    }

    #removeFileBtn:hover svg path {
        fill: var(--blue);
    }

    .hyperlinks:hover {
        text-decoration: none;
    }

    .controls:hover {
        opacity: 1;
    }

    .full-screen-btn:hover {
        transform: scale(1.1);
    }

    #leaveBtn:hover,
    #leaveBtn:focus {
        -webkit-box-shadow: 0px 1px 10px var(--red) !important;
        box-shadow: 0px 1px 10px var(--red) !important;
        background-color: var(--red) !important;
    }

    .confirm-btn:hover,
    .confirm-btn:focus {
        -webkit-box-shadow: 0px 1px 10px var(--red) !important;
        box-shadow: 0px 1px 10px var(--red) !important;
        background-color: var(--red) !important;
    }

    .file-received:hover {
        background-color: var(--chatMessageColor);
    }

    .custom-input-wrapper:hover .checkmark {
        background-color: var(--chatMessageColor);
    }

}

/* if backdrop support: very transparent and blurred */
@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    .overlay {
        background-color: var(--overlayColor);
        -webkit-backdrop-filter: blur(15px);
        backdrop-filter: blur(15px);
    }

    #box {
        background-color: #57ded84d;
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
    }

    .video-wrapper .please-wait {
        background-color: var(--overlayColor);
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(5px);
    }

    @media(max-width:1024px) {
        #sideBar {
            background-color: #47474180;
            -webkit-backdrop-filter: blur(20px);
            backdrop-filter: blur(20px);
        }
    }
}